import { ChangeDetectorRef, Component, inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeModule, MatTreeNestedDataSource } from '@angular/material/tree';
import { ActivatedRoute } from '@angular/router';
import { DesignService } from '@app/shared/services/design.service';
import { Store } from '@ngrx/store';
import { loadSourceTree, loadTargetTree } from '@app/shared/store/actions/standardise-design.actions';
import { selectSourceTree, selectTargetTree, selectTargetDesignStructure } from '@app/shared/store/selectors/design.selector';
import { TreeStructureComponent } from "../tree-structure/tree-structure.component";
import { StatusDialogComponent } from '../status-dialog/status-dialog.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { DesignPropertiesComponent } from './components/design-properties/design-properties.component';

interface TreeNode {
  name: string;
  children?: TreeNode[];
  isDropPlaceholder?: boolean;
  showAddButton?: boolean;
  files?: File[];
  droppedFileName?: string;
  uploadedFileName?: string;
}

@Component({
  selector: 'app-standardise-designs',
  standalone: true,
  imports: [MatTreeModule, MatIconModule, MatButtonModule, TreeStructureComponent, MatDialogModule, StatusDialogComponent],
  templateUrl: './standardise-designs.component.html',
  styleUrl: './standardise-designs.component.scss',
})
export class StandardiseDesignsComponent implements OnInit {
  leftTreeControl = new NestedTreeControl<TreeNode>((node) => node.children);
  rightTreeControl = new NestedTreeControl<TreeNode>((node) => node.children);
  leftDataSource = new MatTreeNestedDataSource<TreeNode>();
  rightDataSource = new MatTreeNestedDataSource<TreeNode>();
  draggedNode: TreeNode | null = null;
  showAddFolderForm = false;
  currentParentNode: TreeNode | null = null;
  jobCode = 'US-HEQ-2025';
  showSplitScreen = false;
  targetStructure: any;

  constructor(private cdr: ChangeDetectorRef, private store: Store<any>, private designService: DesignService, public dialog: MatDialog) { }

  route = inject(ActivatedRoute);

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.jobCode = params.get('job_code') ?? '';
      if (this.jobCode) {
        this.fetchTreeData();
      }
    });
  }

  fetchTreeData() {
    this.store.dispatch(loadSourceTree({ folder: this.jobCode }));
    this.store.dispatch(loadTargetTree({ folder: this.jobCode }));
    this.store.select(selectSourceTree).subscribe((sourceTree: TreeNode[]) => {
      const source = this.rightDataSource;
      source.data = sourceTree;
      this.rightDataSource = source;
      this.rightTreeControl.dataNodes = sourceTree;
      this.rightTreeControl.expandAll();
    })

    this.store.select(selectTargetTree).subscribe((targetTree: TreeNode[]) => {
      const target = this.leftDataSource;
      target.data = targetTree;
      const prevExpansionModel = this.leftTreeControl.expansionModel.selected;
      console.log(prevExpansionModel, "prev");
      this.leftDataSource = target;
      this.leftTreeControl.dataNodes = targetTree;
      prevExpansionModel.forEach(object => this.leftTreeControl.expand(object));
      this.leftTreeControl.expandAll();
    })

    this.store.select(selectTargetDesignStructure).subscribe((data: any) => {
      this.targetStructure = data;
      console.log('design: ', data);
    })
  }

  onSourceClick() {
    this.showSplitScreen = true;
  }


  private isNodeEqual(node1: TreeNode, node2: TreeNode): boolean {
    return node1.name === node2.name && node1.droppedFileName === node2.droppedFileName;
  }

  displayProperties() {
      this.dialog.open(DesignPropertiesComponent, {
        backdropClass:'custom-dialog',
        data: {
          jobCode: this.jobCode,
          selectedNode: null,
          formType: 'design'
        },
        width: '600px',
      });
  }

  onSave() {
    this.targetStructure = { ...this.targetStructure, method_code: "3" };
    this.designService.saveFolderStructure(this.targetStructure).subscribe(() => {
      this.openDialog('Design saved', 'Design saved successfully!');
    });
  }

  goBack() {
    window.history.back();
  }


  onStandardize() {
    console.log('Before standardization:', this.targetStructure);
    this.targetStructure = { ...this.targetStructure, status: 'STANDARDIZED',method_code: "3" };
    console.log('After standardization:', this.targetStructure);

    this.designService.saveFolderStructure(this.targetStructure).subscribe({
      next: () => {
        console.log('Design saved successfully');

        this.openDialog('Design standardized', 'The design has been standardized!');
      },
      error: (err) => {
        console.error('Error saving the design:', err);
        console.log('Request body sent:', this.targetStructure);
      }
    });
  }


  openDialog(title: string, message: string): void {
    this.dialog.open(StatusDialogComponent, {
      backdropClass:'custom-dialog',
      data: { title, message },
      width: '400px',
    });
  }

  hasChild = (_: number, node: TreeNode) => !!node.children && node.children.length > 0;

}
