import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { Component, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButton } from '@angular/material/button';
import { PopupLabels } from '../../../utils/editor-util';
import { MatIcon } from '@angular/material/icon';

export type DialogType =
  | 'confirmContinue'
  | 'approveSuccess'
  | 'confirmReject'
  | 'rejectSuccess'
  | 'confirmCancel';
  
@Component({
  selector: 'app-personalized-resource-dialogs',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatProgressSpinnerModule,
    MatButton,
    MatIcon
  ],
  templateUrl: './personalized-resource-dialogs.component.html',
  styleUrl: './personalized-resource-dialogs.component.scss',
})
export class PersonalizedResourceDialogsComponent {
  labels = PopupLabels;
  comments: string = '';
  error: string | null = null;
  userType: 'AE' | 'CU';

  constructor(
    public dialogRef: MatDialogRef<PersonalizedResourceDialogsComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      panelClass: string;
      type: DialogType;
      resourceName?: string;
      rejectComments?: string;
      images?: string[];
      userType?: 'AE' | 'CU';
      onApprove?: () => void;
      onYes?: (comments?: string) => Promise<void>;
      onNo?: () => void;
      onOk?: () => void;
    }
  ) {
    this.userType = this.data.userType || 'AE';
  }

  getTitle(): string {
    return this.labels[this.data.type].title;
  }

  isAE(): boolean {
    return this.data.userType === 'AE';
  }

  isCustomer(): boolean {
    return this.data.userType === 'CU';
  }

  oncancelClick(): void {
    if (this.data.onNo) {
      this.data.onNo();
    }
    this.dialogRef.close();
  }
  onYesClick(): void {
    this.error = null;

    if (this.data.type === 'confirmReject' && !this.comments.trim()) {
      this.error = 'Comments are required for rejection';
      return;
    }

    if (this.data.onYes) {
      this.dialogRef.close('yes');

      this.data
        .onYes(this.comments)
        .then(() => {
          console.log('Approval process successful');
        })
        .catch((err: any) => {
          this.error = 'An error occurred during approval. Please try again.';
          console.error('Error during approval process:', err);
        });
    } else {
      this.dialogRef.close('approve');
    }
  }

  onOkClick(): void {
    if (this.data.onOk) {
      this.data.onOk();
    }
    this.dialogRef.close();
  }
  onNoClick(): void {
    this.dialogRef.close('no');
  }
  onSaveClick(): void {
    this.dialogRef.close({
      comment: this.comments,
    });
  }
}
