import { Component, inject, Inject, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
  MatDialog,
} from '@angular/material/dialog';
import { MatTreeModule, MatTreeNestedDataSource } from '@angular/material/tree';
import { MatIconModule } from '@angular/material/icon';
import { NestedTreeControl } from '@angular/cdk/tree';
import { FolderContent, FolderResponse, TreeNode } from '../../model/tree-node';
import { ImagePreviewComponent } from '../image-preview/image-preview.component';
import { MatButton } from '@angular/material/button';
import { Router } from '@angular/router';
import { SharedDataService } from '../../../../shared/services/shared-data.service';
import { TemplateService } from '../../../../shared/services/template.service';
import { DesignService } from '@app/shared/services/design.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { JwtService } from '@app/core/services/jwt.service';

@Component({
  selector: 'app-folder-structure',
  standalone: true,
  imports: [
    MatTreeModule,
    MatIconModule,
    MatDialogModule,
    MatButton,
    MatProgressSpinnerModule
  ],
  templateUrl: './folder-structure.component.html',
  styleUrl: './folder-structure.component.scss',
})
export class FolderStructureComponent implements OnInit {
  treeControl = new NestedTreeControl<TreeNode>(node => node.children);
  nestedTreeControl = new NestedTreeControl<TreeNode>(node => node.children);
  dataSource = new MatTreeNestedDataSource<TreeNode>();
  nestedDataSource = new MatTreeNestedDataSource<TreeNode>();
  selectedImageUrl: string | null = null;
  design: any;
  bucketName='';
  isLoading = true;
  downloadingFiles: { [key: string]: boolean } = {};
  jwtService = inject(JwtService);
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<FolderStructureComponent>,
    private router: Router,
    private dialog: MatDialog,
    private templateService: TemplateService,
    private sharedDataService: SharedDataService,
    private designService:DesignService
  ) {
    this.design = data.design;
  }

  ngOnInit() {
    const jobCode = this.data.design?.job_code;

    this.templateService.getDesignFolderStructure(`${jobCode}/design`).subscribe((data: any) => {
      const response: FolderResponse = data as FolderResponse;
      const treeData = this.buildTreeFromApi(response.body.contents);
      this.bucketName=response.body.bucketname
      this.dataSource.data = treeData;
      this.isLoading = false;
    }, error => {
      console.error('Error fetching folder structure:', error);
      this.isLoading = false;
    });
  }

  buildTreeFromApi(contents: FolderContent[], parentPath: string = ''): TreeNode[] {
    return contents.map(item => {
      const currentPath = parentPath ? `${parentPath}/${item.name}` : item.name;

      if (item.type === 'folder') {
        return {
          name: item.name,
          filePath: currentPath,
          children: this.buildTreeFromApi(item.contents || [], currentPath)
        };
      } else {
        return {
          name: item.name,
          filePath: currentPath,
          // view: true,
          download: true,
        };
      }
    });
  }

  hasChild = (_: number, node: TreeNode) =>
    !!node.children && node.children.length > 0;

  closeDialog() {
    this.dialogRef.close();
  }
  uploadFile(node: TreeNode) {}

  openImagePreview(url: string, title: string) {
    this.dialog.open(ImagePreviewComponent, {
      backdropClass:'custom-dialog',
      data: { imageUrl: url, title: title },
      width: '80%',
      height: '60%',
    });
  }
  closeImagePreview() {
    this.selectedImageUrl = null;
  }

  toggleNode(node: TreeNode) {
    if (this.treeControl.isExpanded(node)) {
      this.treeControl.collapse(node);
    } else {
      this.treeControl.expand(node);
    }
  }
  // downloadFile(url: string, fileName: string) {
  //   this.http.get(url, { responseType: 'blob' }).subscribe((response: Blob) => {
  //     saveAs(response, fileName);
  //   });
  // }

  downloadFile(filePath: string): void {
    this.downloadingFiles[filePath] = true;
    this.designService.previewfile(filePath).subscribe(
      (response: any) => {
        const responseBody = response.body instanceof Object ? response.body : JSON.parse(response.body);
        const previewUrl = responseBody['Preview URL'];

        if (previewUrl) {
          this.fetchFileBlob(previewUrl).then((blob) => {
            this.triggerFileDownload(blob, this.extractFilenameFromUrl(previewUrl));
            this.downloadingFiles[filePath]  = false;
          }).catch((error) => {
            console.error('Error fetching file as a blob:', error);
            this.downloadingFiles[filePath] = false;
          });
        } else {
          console.error('Preview URL not found in response.');
          this.downloadingFiles[filePath] = false;
        }
      },
      (error) => {
        console.error('Error fetching preview URL:', error);
        this.downloadingFiles[filePath]  = false;
      }
    );
}

  private async fetchFileBlob(url: string): Promise<Blob> {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.blob();
  }
  private triggerFileDownload(blob: Blob, filename: string): void {
    const link = document.createElement('a');
    const url = window.URL.createObjectURL(blob);
    link.href = url;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  }
  private extractFilenameFromUrl(url: string): string {
    return url.split('?')[0].split('/').pop() || 'downloaded_file';
  }


  createTemplate() {
    this.onDesignClick(this.design);
  }

  onDesignClick(design: any): void {
    const jobCode = this.design?.job_code;
    const templateName = this.design?.design_name;

    if (jobCode && templateName) {
    const user = this.jwtService.getUserEmail();

      this.templateService.createTemplate(user, jobCode, templateName).subscribe(
        (response: any) => {
          const templateId = response.template_id;
          this.sharedDataService.templateId = response.template_id;
          this.sharedDataService.userId = response.user_id;


          this.router.navigate(['/template-builder', templateId, jobCode]);
        },
        (error: any) => {
          console.error('Error creating template:', error);
        }
      );
    } else {
      console.error('Job code or template name is undefined');
    }
  }
}
