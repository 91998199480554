<div class="mat-tree-node node-content" [draggable]="node.draggable" (dragleave)="onDragEnd($event)" (dragend)="onDragEnd($event)" (dragstart)="onDragStart($event, node)" (dragover)="onDragOver($event, node)" (drop)="onDrop($event, node)">
  @if (hasChild) {
    <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name">
      <mat-icon>{{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}</mat-icon>
    </button>
  }
  {{ node.name }}
  @if (dropPlaceholder) {
    <div class="drop-placeholder"><div class="badge bg-secondary">Drop here</div></div>
  }

  @if (node.upload) {
    <span>: {{ node.uploadedFileName }}</span>
  }

  @if (node.upload) {
    <div>
      <button mat-icon-button (click)="fileInput.click()">
        <mat-icon>upload</mat-icon>
      </button>
      <input type="file" #fileInput (change)="onFileSelected($event, node)" style="display: none;" />
    </div>
  }


  @if (node.addFolder) {
    <button mat-icon-button (click)="onAddFolderClick(node)">
      <mat-icon>add</mat-icon>
    </button>
  }

  @if (node.properties) {
    <button mat-icon-button (click)="editProperties(node)">
      <mat-icon>edit</mat-icon>
    </button>
  }

  @if (!node.children) {
    <button mat-icon-button (click)="removeNode(node)">
      <mat-icon>clear</mat-icon>
    </button>
  }

  @if (showAddFolderForm) {
    <div class="add-folder-form">
      <input mat-form-field #folderNameInput type="text" placeholder="Enter folder name" />
      <button mat-button (click)="onSubmitNewFolder(folderNameInput.value)">Submit</button>
    </div>
  }
</div>