import { endpoints, idp } from '../app/shared/constants/auth.constants';

const cognito_domain = idp.cognito_url.uat;
const identity_provider = idp.identity_provider.uat;
const redirectUrl = idp.redirect_url.uat;
const clientId = idp.client_id.uat;
const clientSecret = idp.client_secret.uat;
const scope = idp.scope;

export const environment = {
  production: false,
  cognito_domain,
  cognitoUrl: `${cognito_domain}/oauth2/token`,
  clientId,
  clientSecret,
  redirectUri: 'https://tbt-uat.healtheq.com/verify-token',
  redirectUriLogout: 'https://tbt-uat.healtheq.com/sign-out',
  pingfedAuthUrl: `${cognito_domain}/oauth2/authorize?identity_provider=${identity_provider}&redirect_uri=${redirectUrl}&response_type=CODE&client_id=${clientId}&scope=${scope}`,
  salesforceAuthService: '',
  designsEndPoint: `/services/apexrest/${endpoints.designs}`,
  templatesEndPoint: `/services/apexrest/${endpoints.templates}`,
  apiGatewayUrl: 'https://2sao0yz3jk-vpce-0e6a4e56e91b1fcdc.execute-api.us-east-1.amazonaws.com/v1/',
  apiProxyApi: 'https://api-qa.merck.com/healtheq-template-builder/v1/',
  portalRedirectLink: 'https://msdlogin--hhusf1.sandbox.my.site.com/healtheq/',
 };