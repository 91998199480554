<span class="header-text">
  Design Folder Structure
  <button type="button" class="btn-close" aria-label="Close" (click)="closeDialog()">
    <i class="bi bi-x"></i>
  </button>
</span>
<mat-dialog-content class="dialog-content">
  @if (isLoading) {
    <div class="loading-spinner">
      <mat-spinner diameter="50"></mat-spinner>
    </div>
  }

  @if (!isLoading) {
    <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="example-tree">
      <!-- Default node definition for leaf nodes -->
      <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
        <div>
          {{ node.name }}
          @if (node.view) {
            <a (click)="openImagePreview(node.link, node.name)">
              <i class="bi bi-eye"></i>
            </a>
          }
          @if (node.download) {
            <a (click)="downloadFile(node.filePath)">
              <i class="bi bi-download"></i>
            </a>
          }
          @if (downloadingFiles[node.filePath]) {
            <div class="loading-spinner">
              <mat-spinner diameter="30"></mat-spinner>
            </div>
          }
          @if (node.upload) {
            <button (click)="uploadFile(node)">
              <i class="bi bi-upload"></i> Upload
            </button>
          }
        </div>
      </mat-tree-node>
      <!-- Nested node definition for nodes with children -->
      <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
        <div class="mat-tree-node">
          <button class="btn btn-light" (click)="toggleNode(node)">
            <i class="bi {{ treeControl.isExpanded(node) ? 'bi-chevron-down' : 'bi-chevron-right' }}"></i>
          </button>
          {{ node.name }}
        </div>
        <div [class.example-tree-invisible]="!treeControl.isExpanded(node)" role="group">
          <ng-container matTreeNodeOutlet></ng-container>
        </div>
      </mat-nested-tree-node>
    </mat-tree>
  }

  <!-- Image Preview Section -->
  @if (selectedImageUrl) {
    <div>
      <h3>Image Preview</h3>
      <img [src]="selectedImageUrl" alt="Selected Image" />
      <button (click)="closeImagePreview()">Close</button>
    </div>
  }
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button mat-dialog-close (click)="createTemplate()">Create Template</button>
</mat-dialog-actions>
